import { Editor } from "tinymce"

const oysterLogo = require("./assets/oyster-logo.svg")

export const oysterpolicy = (editor: Editor) => {
  // Add custom button to the toolbar
  editor.ui.registry.addButton("oysterpolicy", {
    icon: "oyster-logo",
    tooltip: "Wrap with Oyster Policy",
    onAction: () => {
      const selectedHtml = editor.selection.getContent({ format: "html" })
      const style = "display:block; background-color: #f7ecff; color: #9900FF; padding: 12px;"

      // Check if the selected HTML already contains the oyster-policy attribute or is included inside
      // a paragrpah which already has the oyster-policy attribute
      const rng = editor.selection.getRng()
      let parentBlock = editor.dom.getParent(rng.startContainer, "p")
      const containsOysterPolicy = parentBlock?.outerHTML?.includes(
        'data-content-type="oyster-policy"',
      )

      if (containsOysterPolicy && parentBlock) {
        // Remove oyster-policy attribute and style
        editor.dom.removeAllAttribs(parentBlock)
        return
      }

      if (selectedHtml && parentBlock) {
        // Remove style from all child nodes
        const children = Array.from(parentBlock.childNodes)
        children.forEach(child => {
          if (child.nodeType === 1) {
            // Only remove the style attribute from element nodes (nodeType 1)
            ;(child as HTMLElement).removeAttribute("style")
          }
        })
        // If not already wrapped, wrap the paragrpah in a <span> tag with the attribute oyster-policy
        editor.dom.setAttrib(parentBlock, "data-content-type", "oyster-policy")
        editor.dom.setAttrib(parentBlock, "style", style)
      } else {
        // If no text is selected, prompt the user for input
        editor.windowManager.open({
          title: "Insert Oyster Policy Content",
          body: {
            type: "panel",
            items: [
              {
                type: "input",
                name: "content",
                label: "Content",
              },
            ],
          },
          buttons: [
            {
              type: "cancel",
              text: "Cancel",
            },
            {
              type: "submit",
              text: "Insert",
              primary: true,
            },
          ],
          onSubmit: dialogApi => {
            const data = dialogApi.getData()
            if (data.content) {
              // Create a new paragraph with the oyster-policy attribute, the style and the user's input
              const newParagraph = editor.dom.create(
                "p",
                {
                  "data-content-type": "oyster-policy",
                  style,
                },
                data.content,
              )
              if (parentBlock) {
                editor.dom.replace(newParagraph, parentBlock)
              } else {
                editor.insertContent(newParagraph.outerHTML)
              }
            }
            dialogApi.close()
          },
        })
      }
    },
  })

  // Register the custom icon
  editor.ui.registry.addIcon(
    "oyster-logo",
    `<img src="${oysterLogo}" alt="Oyster Policy Icon" style="width: 35px; height: 20px;" />`,
  )

  return {
    getMetadata: () => ({
      name: "Oyster Policy Plugin",
      description:
        'Wrap selected text or input with a <p> tag with data-content-type="oyster-policy".',
    }),
  }
}
